// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-blog-details-js": () => import("./../../../src/pages/blog-details.js" /* webpackChunkName: "component---src-pages-blog-details-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-case-studies-js": () => import("./../../../src/pages/case-studies.js" /* webpackChunkName: "component---src-pages-case-studies-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-history-js": () => import("./../../../src/pages/history.js" /* webpackChunkName: "component---src-pages-history-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-service-details-js": () => import("./../../../src/pages/service-details.js" /* webpackChunkName: "component---src-pages-service-details-js" */),
  "component---src-pages-service-detailsa-js": () => import("./../../../src/pages/service-detailsa.js" /* webpackChunkName: "component---src-pages-service-detailsa-js" */),
  "component---src-pages-service-detailsb-js": () => import("./../../../src/pages/service-detailsb.js" /* webpackChunkName: "component---src-pages-service-detailsb-js" */),
  "component---src-pages-service-detailsbd-js": () => import("./../../../src/pages/service-detailsbd.js" /* webpackChunkName: "component---src-pages-service-detailsbd-js" */),
  "component---src-pages-service-detailsbi-js": () => import("./../../../src/pages/service-detailsbi.js" /* webpackChunkName: "component---src-pages-service-detailsbi-js" */),
  "component---src-pages-service-detailsc-js": () => import("./../../../src/pages/service-detailsc.js" /* webpackChunkName: "component---src-pages-service-detailsc-js" */),
  "component---src-pages-service-detailsd-js": () => import("./../../../src/pages/service-detailsd.js" /* webpackChunkName: "component---src-pages-service-detailsd-js" */),
  "component---src-pages-service-detailsdm-js": () => import("./../../../src/pages/service-detailsdm.js" /* webpackChunkName: "component---src-pages-service-detailsdm-js" */),
  "component---src-pages-service-detailsdv-js": () => import("./../../../src/pages/service-detailsdv.js" /* webpackChunkName: "component---src-pages-service-detailsdv-js" */),
  "component---src-pages-service-detailsdw-js": () => import("./../../../src/pages/service-detailsdw.js" /* webpackChunkName: "component---src-pages-service-detailsdw-js" */),
  "component---src-pages-service-detailse-js": () => import("./../../../src/pages/service-detailse.js" /* webpackChunkName: "component---src-pages-service-detailse-js" */),
  "component---src-pages-service-detailsf-js": () => import("./../../../src/pages/service-detailsf.js" /* webpackChunkName: "component---src-pages-service-detailsf-js" */),
  "component---src-pages-service-detailsg-js": () => import("./../../../src/pages/service-detailsg.js" /* webpackChunkName: "component---src-pages-service-detailsg-js" */),
  "component---src-pages-service-detailsh-js": () => import("./../../../src/pages/service-detailsh.js" /* webpackChunkName: "component---src-pages-service-detailsh-js" */),
  "component---src-pages-service-detailsmdm-js": () => import("./../../../src/pages/service-detailsmdm.js" /* webpackChunkName: "component---src-pages-service-detailsmdm-js" */),
  "component---src-pages-service-detailsz-js": () => import("./../../../src/pages/service-detailsz.js" /* webpackChunkName: "component---src-pages-service-detailsz-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-servicess-js": () => import("./../../../src/pages/servicess.js" /* webpackChunkName: "component---src-pages-servicess-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-testimonials-js": () => import("./../../../src/pages/testimonials.js" /* webpackChunkName: "component---src-pages-testimonials-js" */)
}

